import { all } from "redux-saga/effects";
import authSagas from "./auth/saga";
import dashboardSaga from "./Dashboard/saga";
import botTranscriptSaga from "./BotTranscript/Saga";
import leadSaga from "./Lead/Saga";
import customer from "./Customer/Saga";
import user from "./Users/Saga";
import complaint from "./Complaint/Saga";
import partnerLead from "./PartnerLead/Saga";
import report from "./Report/Saga";
import company from "./Company/Saga";
import complaintType from "./ComplaintType/Saga";
import customerQuery from "./CustomerQuery/Saga";
import hospitals from "./Hospitals/Saga";
import dropdown from "./Dropdown/Saga";
import claimFilingSaga from "./ClaimFiling/Saga";
import searchComplaint from "./SearchComplaint/Saga";
import DocumentUploadSaga from "./DocumetUpload/saga";
import InsaEngineSaga from "./InsaEngine/Saga";
import CronConfigSaga from "./CronJons/Saga";
import { VersionActions } from "containers/navs/versionUpdate/updateAction";
import manualInvoiceUpdateSaga from "./updateInvoice/Saga";

export default function* rootSaga() {
  yield all([
    authSagas(),
    dashboardSaga(),
    botTranscriptSaga(),
    leadSaga(),
    customer(),
    user(),
    complaint(),
    report(),
    partnerLead(),
    company(),
    complaintType(),
    customerQuery(),
    hospitals(),
    dropdown(),
    claimFilingSaga(),
    searchComplaint(),
    DocumentUploadSaga(),
    InsaEngineSaga(),
    CronConfigSaga(),
    VersionActions(),
    manualInvoiceUpdateSaga(),
  ]);
}

import {
  UPDATE_INVOICE_SUBMIT_FORM_REQUEST,
  UPDATE_INVOICE_SUBMIT_FORM_SUCCESS,
  UPDATE_INVOICE_SUBMIT_FORM_FAILURE,
  RESET_STATE
} from "./Action";

const initialState = {
  success: null,
  error: null,
  message: "",
};

const updateInvoice = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_INVOICE_SUBMIT_FORM_REQUEST:
      return { ...state, success: false, error: null };
    case UPDATE_INVOICE_SUBMIT_FORM_SUCCESS:
      return {
        ...state,
        success: true,
        message: action.message,
      };
    case UPDATE_INVOICE_SUBMIT_FORM_FAILURE:
      return {
        ...state,
        success: false,
        message: action.message,
      };
    case RESET_STATE:
      return { ...state, success: null, error: null, message: "" };
    default:
      return { ...state };
  }
};

export default updateInvoice;

import {
    FETCH_APP_VERSION_REQUEST,
    FETCH_APP_VERSION_SUCCESS,
    FETCH_APP_VERSION_FAILURE,
    UPDATE_VERSION_REQUEST,
    UPDATE_VERSION_SUCCESS,
    UPDATE_VERSION_FAILURE,
  } from './versionAction';
  const initialState = {
    appVersionData: [],
    fetchLoading: false,
    updateLoading: false,
    error: null,
    success : false ,
  };
  const versionReducer = (state = initialState, action) => {
    switch (action.type) {
      case FETCH_APP_VERSION_REQUEST:
        return {
          ...state,
          fetchLoading: true,
          error: null,
        };
      case UPDATE_VERSION_REQUEST:
        return {
          ...state,
          updateLoading: true,
          error: null, 
          success : false
        };
      case FETCH_APP_VERSION_SUCCESS:
        return {
          ...state,
          appVersionData: action.payload,
          fetchLoading: false,
        };
      case UPDATE_VERSION_SUCCESS:
        return {
          ...state,
          updateLoading: false,
          success : true
        };
      case FETCH_APP_VERSION_FAILURE:
      case UPDATE_VERSION_FAILURE:
        return {
          ...state,
          error: action.payload,
          fetchLoading: false,
          updateLoading: false,
          success : false
        };
      default:
        return state;
    }
  };
  export default versionReducer;